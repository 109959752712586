/**
 * Arquivo destinado a inicialização das classes javascript. 
 */
(function($) {

    window.isIE8 = ($('html').hasClass('lt-ie9') === true) ? true : false;

    var maxContainerWidth = 940,
        maxSmallScreenWidth = 1024,
        menu,
        resizeVideos,
        body = $('body');

    resizeVideos = (function() {
        $.each($('.player-proportion'), function (i, val) {
            var player = $(this),
                image = player.parent().find('.image-proportion');

            function resized() {
                player.width('auto');
                player.height('auto');
                player.css({
                    width: image.width(),
                    height: image.height(),
                    'margin-top': image.height() * -1
                })
            }

            image.on('load', function () {
                body.on('resized', function () {
                    resized();
                });
                resized();
                player.playerTube();
            }).attr('src', image.attr('data-src')).removeAttr('data-src');

        });
    }());

    $.fn.serializeObject = function () {
        var o = {},
            a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    window.getInnerWidth = function() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    window.getInnerHeight = function() {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    menu = (function () {

        var scope = $('header .menu'),
            toggleButton = $('.toogle-menu'),
            toogleButtonLeft = 0,
            isOppened = false,
            initialized = false;

        function refreshToogleButtonLeft() {
            if (isOppened === true) {
                toogleButtonLeft = scope.width() + 1;
            } else {
                if (maxContainerWidth >= getInnerWidth()) {
                    toogleButtonLeft = 0;
                } else {
                    if (getInnerWidth() > maxSmallScreenWidth) {
                        toogleButtonLeft = (($('header').width() / 2) - (maxContainerWidth / 2));
                        if ($('header').hasClass('home') === true) {
                            toogleButtonLeft = 42;
                        }
                    } else {
                        toogleButtonLeft = (($('header').width() / 2) - (maxContainerWidth / 2));
                    }
                }
            }
            TweenMax.to(toggleButton, 0.3, {left: toogleButtonLeft, onComplete: function () {
                if (initialized === false) {
                    initialized = true;
                    toggleButton.slideDown();
                }
            }});
        }

        function refreshScroll() {
            var currScroll = window.pageYOffset,
                menuHeight = scope.outerHeight(),
                difference = menuHeight > window.getInnerHeight() ? menuHeight - window.getInnerHeight() : 0;
            if (difference > 0) {
                scope.css({
                    top: (currScroll > difference ? difference : currScroll) * -1
                });
            }
        }

        function refreshHeight() {
            // scope.height('auto');
            // console.log('sdfsdfdsf', scope.height(),' <= ',window.getInnerHeight());
            // scope.height('auto');
            // var menuHeight = scope.height() + scope.css('padding-bottom');
            // if (menuHeight <= window.getInnerHeight()) {
            //     scope.addClass('fixed');
            // } else {
            //     scope.removeClass('fixed');
            // }
            // if (scope.outerHeight() < $('body').height()) {
            //     scope.outerHeight($('body').height());
            // }
        }

        function onRefreshedSize() {
            if (window.getInnerWidth() < 720 || $('html').hasClass('touch')) {
                scope.addClass('mobile');
            } else {
                scope.removeClass('mobile');
            }
            refreshToogleButtonLeft();
            refreshHeight();
        }

        function show() {
            isOppened = true;
            scope.show();
            TweenMax.to(scope, 0.3, {left: 0});
            toggleButton.addClass('opened');
            scope.addClass('opened');
            refreshToogleButtonLeft();
        }

        function hide() {
            isOppened = false;
            TweenMax.to(scope, 0.3, {left: -260, onComplete: function () {
                scope.hide();
            }});
            toggleButton.removeClass('opened');
            scope.removeClass('opened');
            refreshToogleButtonLeft();
        }

        toggleButton.on('click', function () {
            refreshToogleButtonLeft();
            if (isOppened === true) {
                hide();
            } else {
                show();
            }
        });

        onRefreshedSize();

        refreshToogleButtonLeft();

        body.on('resized', function () {
            onRefreshedSize();
            refreshScroll();
        });

        body.on('scrolling', function () {
            refreshScroll();
        });

    }());

    window.onresize = function () {
        body.trigger('resized');
    }

    window.onscroll = function () {
        body.trigger('scrolling');
    }

})(jQuery);