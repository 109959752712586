/**
 * Sessão HOME
 */
(function($) {

    var section = $('section.home'),
        balloons;

    if (section.length === 0) { return; }

    balloons = (function () {
        var items,
            balloonItems = section.find('.balloons-menu .box-content'),
            menuItems = section.find('.balloons-menu .item'),
            currIndex = -1,
            backgrounds = section.find('.backgrounds .background');

        function resetItems() {
            if (isIE8 === true) {
                menuItems.find('img').css({
                    width: '175px',
                    height: '193px',
                    left: ((220 / 2) - (175/2)) + 'px'
                });
            } else {
                menuItems.find('img').css({
                    transform: 'matrix(0.795454545454546, 0, 0, 0.795454545454546, 0, 0)',
                    'margin-top': 49 / 2 + 'px'
                });
            }
        }

        function changeItem(index) {
            index = index || 0;
            if (index === currIndex) { return; }

            currIndex = index;

            var lastMenuItem = menuItems.filter('.selected').eq(0),
                currMenuItem = menuItems.eq(currIndex).addClass('selected'),
                lastMenuItemImg = lastMenuItem.find('img');
                currMenuItemImg = currMenuItem.find('img');
                lastBalloonItem = balloonItems.filter('.selected').eq(0),
                currBalloonItem = balloonItems.eq(currIndex).addClass('selected'),
                lastBg = backgrounds.filter('.selected').eq(0),
                currBg = backgrounds.eq(currIndex).addClass('selected'),
                textObj = {};

            textObj.widthOut = '100%';
            textObj.marginOut = 0;
            textObj.widthOver = '100%';
            textObj.marginOver = 0;
            if (window.getInnerWidth() < 960) {
                textObj.widthOver = section.find('.container').width() - 40;
                textObj.marginOver = '0 20px';
            }

            if (lastMenuItem.length > 0) {
                lastMenuItem.removeClass('selected');
                lastBalloonItem.removeClass('selected');
                lastBg.removeClass('selected');
                if (isIE8 === true) {
                    TweenLite.to(lastMenuItemImg, .3, {width: '175px', height: '193px', 'left': ((220 / 2) - (175/2)) + 'px', top: 45});
                } else {
                    TweenLite.to(lastMenuItemImg, .3, {scale: '0.7954545454545455', 'margin-top': 49 / 2 + 'px'});
                }
                // TweenLite.to(lastBalloonItem, .3, {bottom: 400, alpha: 0, onCompleteParams: [lastBalloonItem], onComplete: function (node0) {
                //     node0.hide();
                // }});
                TweenLite.to(lastBalloonItem, .3, {alpha: 0, onComplete: function (lastBalloonItem) {
                    lastBalloonItem.css({
                        bottom: 0
                    });
                }, onCompleteParams: [lastBalloonItem]});
                TweenLite.to(lastBg, .3, {alpha: 0, onComplete: function (node1) {
                    node1.hide();
                }, onCompleteParams: [lastBg]});
                TweenLite.to(lastMenuItem, .3, {height: 350});
                TweenLite.to(lastMenuItem.find('.background'), .3, {alpha: 0, onComplete: function (nodeBg) {
                    nodeBg.hide();
                }, onCompleteParams: [lastMenuItem.find('.background')]});
                TweenLite.to(lastMenuItem.find('>a >.text'), .3, {width: textObj.widthOut, margin: textObj.marginOut});
            }

            if (isIE8 === true) {
                TweenLite.to(currMenuItemImg, .3, {width: '220px', height: '242px', left: 0, top: 0});
            } else {
                TweenLite.to(currMenuItemImg, .3, {scale: '1', 'margin-top': '0px'});
            }
            // currBalloonItem.show();
            // TweenLite.to(currBalloonItem, .3, {bottom: 350, alpha: 1});
            currBalloonItem.css({
                bottom: 350
            });
            currBalloonItem.css({
                opacity: 0.01
            });
            TweenLite.to(currBalloonItem, .3, {alpha: 1});
            currBg.show();
            TweenLite.to(currBg, .3, {alpha: 1});
            TweenLite.to(currMenuItem, .3, {height: 350 + currBalloonItem.height() + 20});
            currMenuItem.find('.background').show();
            TweenLite.to(currMenuItem.find('.background'), .3, {alpha: 1});
            TweenLite.to(currMenuItem.find('>a >.text'), .3, {width: textObj.widthOver, margin: textObj.marginOver});

            // refreshBoxContentSize();
            // setTimeout(function () {
            //     refreshBoxContentSize();
            // },50);
        }

        function refreshBoxContentPosition() {
            var items = section.find('.item'),
                itemsContainer = section.find('.items'),
                itemsContainerOffsetLeft = itemsContainer.offset().left;

            $.each(items, function (i, val) {
                var item = $(val),
                    box = item.find('.box-content'),
                    itemOffsetLeft = item.offset().left,
                    left;

                if (window.getInnerWidth() >= 960 && itemOffsetLeft > itemsContainerOffsetLeft) {
                    left = (itemOffsetLeft - 10) - itemsContainerOffsetLeft;
                    left = left * -1;
                } else {
                    left = 20;
                }
                box.css({
                    left: left
                    // bottom: box.hasClass('selected') ? 400 : 350
                });
            });
        }

        function refreshBoxContentSize() {
            var items = section.find('.box-content a .content:visible'),
                maxBoxWidth = section.find('.container').innerWidth(),
                menuItems = section.find('.item'),
                boxWidth,
                menuItemHeight,
                itemText = {};

            if (window.getInnerWidth() >= 960) {
                menuItems.width(220);
                boxWidth = maxBoxWidth;
                itemText.width = '100%';
                itemText.padding = 0;
            } else if (maxBoxWidth < 960) {
                menuItems.width(maxBoxWidth);
                boxWidth = maxBoxWidth - 40;
                itemText.width = boxWidth;
                itemText.padding = '0 20px';
            }

            $.each(items, function (i, val) {
                var c = $(val),
                    box = c.parent().parent();

                box.width(boxWidth);

                box.height('auto');
                c.height('auto');
                box.height(c.outerHeight() + 17);

                menuItems.find('.name').width(menuItems.width());

                box.parent().filter('.selected').height(350 + (box.height()) + 20);

                box.parent().filter('.selected').find('.text').css({
                    width: itemText.width,
                    margin: itemText.padding
                });
            });
        }

        function refreshBackgroundSize() {
            var imgSize = [1920, 1047],
                scaledSize = [],
                scale,
                left,
                top,
                imgs = backgrounds.find('img');

            function getScaledNumber(scale, number) {
                return scale * number / 1;
            }

            scale = window.getInnerWidth() * 1 / imgSize[0];
            scaledSize = [getScaledNumber(scale, imgSize[0]), getScaledNumber(scale, imgSize[1])];

            if (scaledSize[0] < window.getInnerWidth() || scaledSize[1] < getInnerHeight()) {
                scale = window.getInnerHeight() * 1 / imgSize[1];
                scaledSize = [getScaledNumber(scale, imgSize[0]), getScaledNumber(scale, imgSize[1])];
            }

            left = (scaledSize[0] / -2);
            top = (scaledSize[1] / -2);

            imgs.css({
                width: scaledSize[0],
                height: scaledSize[1],
                'margin-left': left,
                'margin-top': top
            });
        }

        function onRefreshedSize() {
            refreshBoxContentPosition();
            refreshBoxContentSize();
            refreshBackgroundSize();

            if (window.getInnerWidth() >= 960) {
                section.height(800);
            } else {
                section.height('auto');
            }
        }

        function init() {
            onRefreshedSize();
            menuItems.on('mouseenter click', 'a.menu', function (e) {
                e.preventDefault();
                var currEvent = 'mouseenter';
                if ($('html').hasClass('touch')) {
                    currEvent = 'click';
                }
                if (e.type === currEvent) {
                    changeItem($(this).parent().index());
                }
            });
            resetItems();
            changeItem();
        }

        init();

        $('body').on('resized', function () {
            onRefreshedSize();
        });
    }());

})(jQuery);