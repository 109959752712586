(function ( $ ) {
 
    $.fn.Sample = function() {
     
        return this.each(function() {
            

            


        });
     
    };

}( jQuery ));