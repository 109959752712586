/**
 * Sessão Fale conosco
 */
(function($) {

    var section = $('section.fale-conosco'),
    form,
    fieldMessage,
    map;

    if (section.length === 0) { return; }

    form = (function () {
        var scope = section.find('form'),
            btnSend = scope.find('.btn-send'),
            phoneMinChars = String('(34) 23432-343').length;

        $(".field input[name=phone]").mask("(99) 99999-9999");

        function alignText(text) {
            var height = text.height();
            text.css({
                'margin-top': (20 - (height / 2))
            });
        }

        function resetMessages() {
            scope.find('.error-messages >div').hide();
        }

        function showError(msg) {
            resetMessages();
            alignText(scope.find('.error-message').show().find('.text').text(msg));
        }

        function showSuccess(msg) {
            resetMessages();
            alignText(scope.find('.success-message').show().find('.text').text(msg));
        }

        scope.RSV({
            customErrorHandler: function (f, errorInfo) {
                scope.find('.field.error').removeClass('error');
                if (errorInfo.length > 0) {
                    // $(errorInfo[0][0]).show().focus().parent().addClass('error').find('.placeholder').hide();
                    $(errorInfo[0][0]).show().focus();
                    $.each(errorInfo, function (i, val) {
                        $(val[0]).parent().addClass('error');
                    });
                    showError(errorInfo[0][1]);
                } else {
                    resetMessages();
                    btnSend.addClass('btn-grey').attr('disabled', 'disabled').text(btnSend.attr('data-text-loading'));
                    setTimeout(function () {
                        btnSend.removeClass('btn-grey').removeAttr('disabled').text(btnSend.attr('data-text-send'));
                        showSuccess('Enviado com sucesso!');
                        scope.get(0).reset();
                        scope.find('input, textarea').trigger('focusout');
                    }, 1000);
                }
                return false;
            },
            rules: [
                'required,first_name,Preencha o campo nome.',
                'required,email,Preencha o campo e-mail.',
                'valid_email,email,Digite um e-mail válido.',
                'required,phone,Preencha o campo telefone.',
                'length>=' + phoneMinChars + ',phone,Preencha corretamente o campo telefone.',
                'length<=' + (phoneMinChars + 1) + ',phone,Preencha corretamente o campo telefone.',
                'required,subject,Preencha o campo assunto.',
                'required,message,Preencha o campo mensagem.'
            ]
        });
    })();

    fieldMessage = (function () {
        var scope = section.find('.field-message'),
            textarea = scope.find('textarea'),
            counter = scope.find('.counter'),
            maxLength = parseInt(textarea.attr('maxlength')),
            text = counter.text();

        function refreshCounter() {
            var chrRemain = maxLength - textarea.val().length;
            counter.text(text.split('##').join(chrRemain));
            if (counter.is(':visible') === false) {
                counter.show();
            }
        }

        textarea.on('keyup', function () {
            refreshCounter();
        });

        refreshCounter();
    }());

    map = (function () {

        var scope = section.find('.map'),
            gMap,
            mapIcon = 'Content/deploy/img/pin-map.png',
            list = [],
            initialized = false,
            markers = [],
            windows = [],
            mapPlugin;

        function addMarker() {
            var latlng,
                infowindow,
                marker;

            latlng = new google.maps.LatLng(-29.9904832, -51.1162124);

            infowindow = new google.maps.InfoWindow({
                content: '<div id="content">' + section.find('.address').text() + '</div>'
            });

            marker = new google.maps.Marker({
                position: latlng,
                map: gMap,
                title: section.find('.address').text(),
                icon: mapIcon
            });

            markers.push(marker);
            windows.push(infowindow);

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(gMap, marker);
            });
        }

        function init() {
            if (initialized === false) {

                initialized = true;

                mapOptions = {
                    zoom: 15,
                    center: new google.maps.LatLng(-29.9904832, -51.1162124),
                    mapTypeControl: false,
                    zoomControl: false,
                    panControl: false,
                    streetViewControl: false
                };

                gMap = new google.maps.Map(scope.get()[0], mapOptions);

                addMarker();

            }
        }

        init();
    }());

})(jQuery);