(function($) {

    if (Modernizr.input.placeholder === true) {
        return;
    }

    $.fn.placeholderLabel = function () {
        var fields = $(this);

        function build(field) {
            field.after('<label class="placeholder">' + field.attr('placeholder') + '</label>');
            var placeholder = field.parent().find('.placeholder');
            placeholder.css({
                width: field.parent().width() - 2,
                height: field.parent().height() - 2
            })

            function focusin() {
                //if (field.is(':visible') === false) {
                placeholder.hide();
                field.show();
                // }
            }

            function focusout() {
                if (field.val().length === 0) {
                    placeholder.show();
                    field.hide();
                }
            }

            placeholder.on('focusin click', function (e) {
                focusin();
                field.focus();
            });

            focusout();

            field.on('focusin focusout', function (e) {
                // console.log(e.type);
                if (e.type === 'focusin') {
                    focusin();
                } else {
                    focusout();
                }
            })
        }

        $.each(fields, function (i, val) {
            build($(val));
        });

    };

    $('input, textarea').placeholderLabel();

})(jQuery);