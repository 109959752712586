/**
 * Sessão produtos
 */
(function($) {

    var section = $('section.produtos'),
        fieldKeyword,
        fieldCategory;

    if (section.length === 0) { return; }

    fieldKeyword = (function () {
        var scope = section.find('.field-keyword'),
            input = scope.find('input'),
            btn = scope.find('.btn-keyword');

        btn.on('click', function (e) {
            e.preventDefault();
            input.focus();
        });
    }());

    fieldCategory = (function () {
        var scope = section.find('.field-category'),
            label = scope.find('label');
            select = scope.find('select');

        function changeLabel() {
            label.text(select.find('option:selected').text());
        }

        select.on('change', function () {
            changeLabel();
        });

        changeLabel();
    }());

    // $('input').placeholderLabel();

})(jQuery);