(function($) {

    $.fn.tripleList = function () {
        var lists = $(this);

        function build(list) {
            var items = list.find('.items .item');

            function haveImg(item) {
                return item.find('img').length > 0 ? true : false;
            }

            function applyHeight(className, numbers) {
                var currItems = items.filter('.' + className);
                currItems.find('.content').height(numbers[0]);
                currItems.find('.title-box').height(numbers[1]);
                currItems.find('.description').height(numbers[2]);
            }

            function adjustSizeItems() {
                var currItem,
                    lastItem,
                    i,
                    total = items.length,
                    currY = items.eq(0).offset().top,
                    lastY = 0,
                    className,
                    line = 0;
                    currNumbers = [0, 0, 0]; // .content, .title-box, .description

                items.attr('class', 'item');

                for (i = 0; i < total; i++) {
                    lastItem = currItem;
                    lastY = currY;

                    currItem = items.eq(i);
                    currY = currItem.offset().top;
                    if (lastItem && lastY !== currY) {
                        applyHeight(className, currNumbers);
                        line = line + 1;
                        currNumbers = [0, 0, 0];
                    }
                    currY = currItem.offset().top;
                    className = 'line-' + line;
                    currItem.addClass(className);
                    currItem.find('.content').height('auto');
                    currItem.find('.title-box').height('auto');
                    currItem.find('.description').height('auto');
                    currNumbers[0] = currItem.find('.content').height() > currNumbers[0] ? currItem.find('.content').height() : currNumbers[0];
                    currNumbers[1] = currItem.find('.title-box').height() > currNumbers[1] ? currItem.find('.title-box').height() : currNumbers[1];
                    currNumbers[2] = currItem.find('.description').height() > currNumbers[2] ? currItem.find('.description').height() : currNumbers[2];
                    if (i === total - 1) {
                        applyHeight(className, currNumbers);
                    }
                };
            }

            function loadImages() {
                var img = items.find('img').not('.loaded').eq(0);
                if (img.length > 0) {
                    img.on('load', function () {
                        img.removeAttr('data-src').addClass('loaded');
                        loadImages();
                    }).attr('src', img.attr('data-src'));
                } else {
                    adjustSizeItems();
                }
            }

            function init() {
                loadImages();
                $('body').on('resized', function () {
                    adjustSizeItems();
                });
                adjustSizeItems();
            }

            init();
        }

        $.each(lists, function (i, val) {
            build($(val));
        });

    };

    $('.triple-list').tripleList();

})(jQuery);