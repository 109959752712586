/**
 * Sessão detalhe da notícia
 */
(function($) {

    var section = $('section.produto-detalhe'),
        gallery,
        form;

    if (section.length === 0) { return; }

    gallery = (function () {
        var scope = section.find('.gallery'),
            windows = scope.find('.windows .window'),
            types = scope.find('.types .item'),
            currWindowIndex = -1;
            currMaterialIndex = -1;

        function set360(currMaterial) {
            if (currMaterial.hasClass('loaded') === false) {
                currMaterial.ThreeSixty({
                    totalFrames: parseInt(currMaterial.attr('data-total-frames')), // Total no. of image you have for 360 slider
                    endFrame: parseInt(currMaterial.attr('data-total-frames')), // end frame for the auto spin animation
                    currentFrame: 1, // This the start frame for auto spin
                    imgList: '.images', // selector for image list
                    progress: '.preloader', // selector to show the loading progress
                    imagePath: currMaterial.attr('data-image-path'),// 'Content/deploy/img/temp/product/', // path of the image assets
                    filePrefix: '', // file prefix if any
                    ext: '.png', // extention for the assets
                    height: 598,
                    width: 478,
                    navigation: false,
                    onloaded: refreshSizes
                });
                currMaterial.addClass('loaded');
            }
        }

        function resetMaterials(currWindow) {
            currWindow.find('.selected').removeClass('selected');
        }

        function setMaterial(index) {
            currWindow = windows.eq(currWindowIndex);
            resetMaterials(currWindow);
            currMaterialIndex = index;
            currMaterial = currWindow.find('.content').eq(currMaterialIndex);
            currMaterial.addClass('selected');
            currWindow.find('.menu .item').eq(currMaterialIndex).addClass('selected');
            set360(currMaterial);
            refreshSizes();
        }

        function setWindow(index) {
            var lastWindow = windows.filter('.selected'),
                currWindow;

            types.find('.selected').removeClass('selected');
            types.eq(index).find('button').addClass('selected');

            if (index !== lastWindow.index()) {
                lastWindow.removeClass('selected');
                currWindowIndex = index;
                setMaterial(0);
                currWindow = windows.eq(index);
                currWindow.addClass('selected');
            }
            refreshSizes();
        }

        function refreshSizes(withTimeout) {
            var maxWidth = section.find('>.container').width(),
                boxWidth = 600,
                boxHeight = 602,
                sizeContents = 479,
                percent = sizeContents * 1 / boxWidth,
                menuSize = 131,
                menuItemWidth = menuSize - 1,
                menuItemHeight = 120,
                cropMarginTop = 0,
                withTimeout = withTimeout || true;

            if (maxWidth >= 600) {
                boxWidth = maxWidth;
                sizeContents = boxWidth - (menuSize - 10);
            } else if (maxWidth < 600) {
                boxWidth = maxWidth;
                boxHeight = maxWidth;
                sizeContents = Math.floor(percent * maxWidth / 1);
                menuSize = boxWidth - sizeContents;
                menuItemWidth = menuSize - 1;
                menuItemHeight = Math.ceil((boxHeight - 2) / 5);
                boxHeight = (menuItemHeight * 5) + 2;
                cropMarginTop = Math.floor((120 / 2) - (menuItemHeight / 2));
                cropMarginTop = cropMarginTop !== 0 ? cropMarginTop * -1 : 0;
            }
            scope.find('.windows').css({
                width: boxWidth,
                height: boxHeight
            });
            windows.find('.contents').css({
                width: sizeContents + 10
            }).find('.content').css({
                width: sizeContents,
                height: boxHeight - 2
            });
            $.each(windows.find('.content'), function (i, val) {
                var img = $(val).find('img'),
                    visibleImg = $(val).find('img:visible');
                img.css({
                    top: Math.floor(((boxHeight - 2) / 2) - (visibleImg.height() / 2)),
                    left: Math.floor(((sizeContents - 2) / 2) - (visibleImg.width() / 2))
                });
            });
            windows.find('.menu').css({
                width: menuSize
            }).find('.item').css({
                width: menuItemWidth
            }).find('a').css({
                height: menuItemHeight 
            }).parent().find('.crop').css({
                'background-position': '0 ' + cropMarginTop + 'px',
                height: menuItemHeight
            });

            if (withTimeout === true) {
                setTimeout(function () {
                    refreshSizes(false);
                });
            }
        }

        function init() {
            windows.find('.menu .item a').on('click', function (e) {
                e.preventDefault();
                // alert('fgdfg');
                setMaterial($(this).parent().index());
            });
            types.on('click', 'button', function (e) {
                setWindow($(e.target).parent().index());
            });
            refreshSizes();
            $('body').on('resized', function () {
                refreshSizes();
            });
            setWindow(0);
        }


        init();

    }());

    $.each(section.find('.picture'), function (i, val) {
        var picture = $(val),
            img = picture.find('img'),
            legend = picture.find('.legend');

        img.on('load', function () {
            img.removeAttr('data-src').addClass('loaded');
            picture.width(img.width());
            legend.fadeIn();
        }).attr('src', img.attr('data-src'));
    });



    form = (function () {
        var container = section.find('.send-email'),
            scope = container.find('form'),
            btnSend = scope.find('.btn-send');

        function alignText(text) {
            var height = text.height();
            text.css({
                'margin-top': (20 - (height / 2))
            });
        }

        function resetMessages() {
            scope.find('.error-messages >div').hide();
            scope.parent().removeClass('big');
        }

        function showError(msg) {
            resetMessages();
            alignText(scope.find('.error-message').show().find('.text').text(msg));
            scope.parent().addClass('big');
        }

        function showSuccess(msg) {
            resetMessages();
            alignText(scope.find('.success-message').show().find('.text').text(msg));
            scope.parent().addClass('big');
        }

        function show() {
            container.fadeIn();
        }

        function hide() {
            scope.get(0).reset();
            resetMessages();
            container.fadeOut();
        }

        section.find('.social-button.email button').on('click', function () {
            show();
        });

        container.find('.close').on('click', function () {
            hide();
        });

        scope.RSV({
            customErrorHandler: function (f, errorInfo) {
                scope.find('.field.error').removeClass('error');
                if (errorInfo.length > 0) {
                    $(errorInfo[0][0]).show().focus();
                    $.each(errorInfo, function (i, val) {
                        $(val[0]).parent().addClass('error');
                    });
                    showError(errorInfo[0][1]);
                } else {
                    resetMessages();
                    btnSend.addClass('btn-grey').attr('disabled', 'disabled').text(btnSend.attr('data-text-loading'));
                    setTimeout(function () {
                        btnSend.removeClass('btn-grey').removeAttr('disabled').text(btnSend.attr('data-text-send'));
                        showSuccess('Enviado com sucesso!');
                        scope.get(0).reset();
                        scope.find('input, textarea').trigger('focusout');
                    }, 1000);
                }
                return false;
            },
            rules: [
                'required,email,Preencha o campo e-mail.',
                'valid_email,email,Digite um e-mail válido.'
            ]
        });
    })();

})(jQuery);