/**
 * Sessão detalhe da notícia
 */
(function($) {

    var section = $('section.noticia-detalhe'),
        container;

    if (section.length === 0) { return; }

    container = section.find('.container');

    function resize(picture) {
        var img = picture.find('img'),
            legend = picture.find('.legend');

        picture.width('auto');
        img.width('auto');

        if (img.width() > container.width()) {
            picture.width(container.width());
            img.width('100%');
        } else {
            picture.width(img.width());
        }

        if (window.getInnerWidth() < 500) {
            legend.addClass('small');
            // legend.css({
            //     'bottom': 'initial',
            //     'position': 'relative'
            // });
        } else {
            legend.removeClass('small');
            // legend.css({
            //     'bottom': 0,
            //     'position': 'absolute'
            // });
        }
    }

    $.each(section.find('.picture'), function (i, val) {
        var picture = $(val),
            img = picture.find('img'),
            legend = picture.find('.legend');

        img.on('load', function () {
            img.removeAttr('data-src').addClass('loaded');
            $('body').on('resized', function () {
                resize(picture);
            });
            // picture.width(img.width());
            resize(picture);
            legend.fadeIn();
        }).attr('src', img.attr('data-src'));
    });

})(jQuery);