/**
 * Sessão Fale conosco
 */
(function($) {

    var section = $('section.onde-encontrar'),
    map;

    if (section.length === 0) { return; }

    map = (function () {
        var scope = section.find('.map'),
            statesContainer = section.find('.states'),
            states,
            links = scope.find('.links area'),
            select = section.find('.states-select select'),
            list = section.find('.states-list');

        function refreshSelectLabel() {
            select.parent().find('label').text(select.find('option:selected').text());
        }

        function toSelect(state) {
            state = state.toLowerCase();

            select.find('option').removeAttr('selected').filter('[value=' + state + ']').attr('selected', 'selected');

            states.removeClass('selected').filter('.' + state).addClass('selected');

            refreshSelectLabel();

            list.find('>.items >.item').removeClass('selected').filter('[data-state=' + state + ']').addClass('selected');
        }

        function adjustList() {
            var maxOffset = scope.offset().top + scope.height() + 30;

            $.each(list.find('.city:visible'), function (i, val) {
                var city = $(val);
                if (city.offset().top < maxOffset) {
                    city.addClass('small');
                } else {
                    city.removeClass('small');
                }

                // if (window.getInnerWidth())
                // city.addClass('city-' + (city.index() + 1))
            });

            // .city
            //     width 280px 340px
            //     &.city-1
            //         width 220px
            //     &.city-2
            //         width 240px
            //     &.city-3
            //         width 260px
            //     &.city-4
            //         width 260px
            //     &.city-5
            //         width 260px
        }

        function organizeMapAndSelect() {
            var states = ['al', 'am', 'ap', 'ba', 'ce', 'df', 'es', 'go', 'ma', 'mg', 'ms', 'mt', 'pa', 'pb', 'pe', 'pi', 'pr', 'rj', 'rn', 'ro', 'rr', 'rs', 'sc', 'se', 'sp', 'to'],
            i,
            total = states.length,
            state;

            for (i = 0; i < total; i++) {
                state = states[i];
                if (list.find('[data-state=' + state + ']').length > 0) {
                    select.append('<option value="' + state + '">' + state.toUpperCase() + '</option>');
                    statesContainer.append('<div class="state ' + state + '"></div>');
                } else {
                    links.filter('.' + state).remove();
                }
            }
        }

        function init() {
            organizeMapAndSelect();

            states = section.find('.states .state');

            select.on('change', function () {
                var opt = select.find('option:selected');
                toSelect(opt.val());
            });

            links.on('click mouseover mouseout', function (e) {
                e.preventDefault();
                var btn = $(this),
                    state = states.filter('.' + btn.attr('class'));

                if (e.type === 'mouseover') {
                    state.addClass('over');
                } else if (e.type === 'mouseout') {
                    state.removeClass('over');
                } else if (e.type === 'click') {
                    toSelect(btn.attr('class'));
                }

                $('body').on('resized', function () {
                    adjustList();
                });

                adjustList();
            });

            section.addClass('ready');

            refreshSelectLabel();

            // toSelect(select.find('option').eq(1).val());
        }


        init();
    }());

})(jQuery);